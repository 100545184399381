import WithAddonBeforeInfoPanel from '@weights/WithAddonBeforeInfoPanel'

import { UTILIZATION_AMOUNT_COLUMN, CASH_PAYMENT_PANEL_COLUMNS } from '../config'

export default {
    name: 'CashPaymentPanel',
    props: {
        utilizationInfo: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        cashPaymentRecordVO() {
            return this.utilizationInfo.cashPaymentRecordVO
        }
    },
    render(h) {
        if (!this.cashPaymentRecordVO) {
            return null
        }

        return h(WithAddonBeforeInfoPanel, {
            class: 'has-margin-top',
            props: {
                itemColumn: 2,
                title: '现金存缴',
                dataSource: this.utilizationInfo,
                columns: CASH_PAYMENT_PANEL_COLUMNS,
                addonBeforeColumns: [UTILIZATION_AMOUNT_COLUMN]
            }
        })
    }
}
