import { FormModel, Row, Col } from 'ant-design-vue'
import InformationPanel from '@components/InformationPanel'

import PropTypes from 'ant-design-vue/es/_util/vue-types'
import { isFunction } from '@utils'

// eslint-disable-next-line
const DEFAULT_COL_PROPS = { span: 12 }

// eslint-disable-next-line
const DEFAULT_ITEM_STYLE = { margin: '0 0 8px 0' }

// 拥有前置内容的信息面板
export default {
    name: 'WithAddonBeforeInfoPanel',
    props: {
        // 源数据
        dataSource: {
            type: Object,
            default: () => ({})
        },
        loading: Boolean,
        // 标题
        title: PropTypes.any,
        /**
         * 字段配置
         * 传入 `InformationPanel` 中的
         */
        columns: Array,
        /**
         * 传入 `InformationPanel` 中的 `column` 配置
         */
        itemColumn: Number,

        // 前置内容项配置
        addonBeforeColumns: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                dataIndex: PropTypes.string,

                colProps: PropTypes.object,
                colStyle: PropTypes.object,
                itemStyle: PropTypes.object,

                customRender: PropTypes.func,
                renderLabel: PropTypes.func
            }).loose
        ).isRequired
    },
    methods: {
        renderFormItem(column, index) {
            const h = this.$createElement

            const dataSource = this.dataSource
            const value = dataSource[column.dataIndex]

            let label = column.label
            const customRenderLabel = column.renderLabel
            if (isFunction(customRenderLabel)) {
                label = customRenderLabel(value, dataSource, index, column, h)
            }

            let kid = value
            const customRender = column.customRender
            if (isFunction(customRender)) {
                kid = customRender(value, dataSource, index, column, h)
            }

            const props = {
                props: {
                    label
                },
                style: {
                    ...DEFAULT_ITEM_STYLE,
                    ...column.itemStyle
                }
            }

            return h(FormModel.Item, props, [kid])
        },
        renderCol(column, index) {
            const h = this.$createElement

            const props = {
                key: index,
                props: {
                    ...DEFAULT_COL_PROPS,
                    ...column.colProps
                },
                style: column.colStyle || {}
            }

            return h(Col, props, [this.renderFormItem(column, index)])
        },
        renderRow() {
            const h = this.$createElement

            const kids = this.addonBeforeColumns?.map(
                (column, index) => this.renderCol(column, index)
            )

            return h(Row, {}, kids)
        },
        renderAddonBfore() {
            const h = this.$createElement

            const props = {
                props: {
                    layout: 'inline'
                }
            }

            return h(FormModel, props, [this.renderRow()])
        }
    },
    render(h) {
        const props = {
            props: {
                title: this.title,
                loading: this.loading,
                columns: this.columns,
                itemColumn: this.itemColumn,
                dataSource: this.dataSource
            },
            scopedSlots: {
                ...this.$scopedSlots,
                addonBefore: this.renderAddonBfore
            }
        }

        return h(InformationPanel, props)
    }
}
